import Vue from 'vue'
//import './registerServiceWorker'

require('./bootstrap');

// Vuesax Component Framework
import Vuesax from 'vuesax'

Vue.use(Vuesax)

// Theme Configurations
import '../themeConfig.js'

// Globally Registered Components
import './globalComponents.js'

// ACL
import acl from './acl/acl'

import './assets/css/main.css'
import './assets/css/gilroy-font.css'
import './assets/css/rg_app.css'
import './assets/css/shared_app.css'
import './assets/css/document-upload.css'
import 'vuesax/dist/vuesax.css' // Vuesax
import 'material-icons/iconfont/material-icons.css' //Material Icons
import './assets/scss/app-shared.scss'
import '@/assets/scss/data-list-view.scss'
import '@/assets/scss/vuexy/apps/calendar-popup.scss'

// Feather font icon
require('./assets/css/iconfont.css')

// Password font
require('./assets/css/password-font.css')

// Filters
import './filters/calendarFilters'

// Tour
import VueTour from 'vue-tour'

Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')

// VeeValidate
import VeeValidate, {Validator} from 'vee-validate'
import es from './vee-locale/es_custom'

Vue.use(VeeValidate);
Validator.localize('es', es);

// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer'

Vue.use(VueHammer)

// PrismJS
//import 'prismjs'

Vue.config.productionTip = false;

import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

import VueCardFormat from 'vue-credit-card-validation';

Vue.use(VueCardFormat)

import VueSocialSharing from 'vue-social-sharing';

Vue.use(VueSocialSharing);

// Global components
Vue.component('image-from-source', require('./components/supplier/ImageFromSource').default);
Vue.component('big-title', require('./components/themed/BigTitle').default);
Vue.component('sub-title', require('./components/themed/SubTitle').default);
Vue.component('big-title-color', require('./components/themed/BigTitleColor').default);
Vue.component('document-upload', require('./components/supplier/misc/DocumentUpload').default);
Vue.component('load-error', require('./components/LoadError').default);
Vue.component('downloadCsv', require('./components/csv/JsonCSV').default);

import router from "./router";
import store from "./store/store";

import IdleVue from 'idle-vue'

const eventsHub = new Vue()
Vue.use(IdleVue, {eventEmitter: eventsHub, store: store, idleTime: 255000, startAtIdle: false,})

import {mapState, mapMutations, mapActions} from "vuex";
import rgConsole from "./components/mixins/rgConsole";
import notifsHelper from "./components/mixins/notificationsHelper";
import publicApiHelper from "./helpers/publicApiHelper";

Vue.mixin(rgConsole)
Vue.mixin(notifsHelper)
Vue.mixin(publicApiHelper)
Vue.prototype.$isLoading = false;
Vue.prototype.$sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;
// import * as Sentry from "@sentry/vue";
// import {BrowserTracing} from "@sentry/tracing";

import VueGeolocation from 'vue-browser-geolocation';

Vue.use(VueGeolocation);

// Sentry.init({
//    Vue,
//    dsn: "https://9bea77d208024f63b68526857efeac96@o4504403686588416.ingest.sentry.io/4504403722240000",
//    environment: process.env.VUE_APP_ENV,
//    integrations: [
//      new BrowserTracing({
//        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//        tracePropagationTargets: ["localhost", "redgirasol.com", /^\//],
//      }),
//      new Sentry.Replay(),
//    ],
//    replaysSessionSampleRate: 0.1,
//    replaysOnErrorSampleRate: 1.0,
//    tracesSampleRate: 1.0,
// });

import App from './App.vue'

Vue.mixin({
  data: function () {
    return {
      get StaticRolesOptions() {
        return [
          {index: 1, name: "investor", label: "Inversionista"},
          {index: 2, name: "client", label: "Solicitante"},
          {index: 3, name: "supplier", label: "Instalador"},
          {index: 4, name: "supplier_agent", label: "Vendedor"},
          {index: 5, name: "program_executive_manager", label: "Ejecutivo de programa"},
          {index: 6, name: "program_administrator", label: "Administrador de programa"},
          {index: 7, name: "program_commercial_manager", label: "Gerente comercial"},
        ];
      },
      get StorageURL() {
        return "https://cdn.redgirasol.com";
        //return process.env.VUE_APP_STORAGE_URL;
      },
      get SupplierBaseRoute() {
        return "/proveedor";
      },
      get ApplicantBaseRoute() {
        return "/solicitante";
      },
      get InvestorBaseRoute() {
        return "/inversionista";
      },
      get KOFBaseRoute() {
        return "/programas";
      },
      get ApiDomain() {
        return process.env.VUE_APP_API_DOMAIN;
      },
      get WebSiteUrl() {
        return process.env.VUE_APP_WEB_SITE_URL;
      },
      get AppDomain() {
        return process.env.VUE_APP_URL;
      },
      get ShowRGConsoleErrors() {
        return process.env.VUE_APP_SHOW_DEV_CONSOLE_WARNINGS == 'true';
      },
      get ShowRGConsoleWarns() {
        return process.env.VUE_APP_SHOW_DEV_CONSOLE_WARNINGS == 'true';
      },
      get ShowRGConsoleLogs() {
        return process.env.VUE_APP_SHOW_DEV_CONSOLE_WARNINGS == 'true';
      },
      get AuthToken() {
        return process.env.VUE_APP_AUTH_TOKEN;
      },
      get ApiAppToken() {
        return process.env.VUE_APP_API_APP_TOKEN;
      },
      get InvestorOnboardingLastStepPF() {
        return process.env.VUE_APP_INVESTOR_ONBOARDING_LAST_STEP;
      },
      get InvestorOnboardingLastStepPM() {
        return process.env.VUE_APP_INVESTOR_ONBOARDING_LAST_STEP_PM;
      },
      get SupplierOnboardingLastStepPM(){
        return process.env.VUE_APP_SUPPLIER_ONBOARDING_LAST_STEP_PM_MEX;
      },
      get SupplierOnboardingLastStepPF(){
        return process.env.VUE_APP_SUPPLIER_ONBOARDING_LAST_STEP_PF_MEX;
      },
      get ApplicantOnboardingLastStepPF(){
        return process.env.VUE_APP_APPLICANT_ONBOARDING_LAST_STEP_PF_MEX;
      },
      get ApplicantOnboardingLastStepPM(){
        return process.env.VUE_APP_APPLICANT_ONBOARDING_LAST_STEP_PM_MEX;
      },
      get ScoreIssuerKey() {
        return "0028590051";
      }
    }
  },
  computed: {
    ...mapState("auth", ["user", "isLogged", "access_token", "currentProgram", "currentGeolocation", "sessionLocationRegistered"]),
    AccessToken() {
      return this.access_token;
    },
    UserId() {
      if (this.user != null) {
        return this.user.user_id;
      }
    },
    UserPersonType() {
      if (this.user != null) {
        return this.user.user_person_type;
      }
    },
    UserTags() {
      if (this.user != null) {
        return this.user.tags;
      }
    },
    UserRole() {
      if (this.user != null) {
        return this.user.role;
      }
    },
    LoanLimits() {
      if (this.user != null) {
        return this.user.loan_limits;
      }
    },
    SupplierId() {
      if (this.user != null) {
        return this.user.supplier_id;
      }
    },
    SupplierStatus() {
      if (this.user != null) {
        return this.user.supplier_status;
      }
    },
    SupplierLevel() {
      if (this.user != null) {
        return this.user.supplier_level;
      } else {
        return "N/A";
      }
    },
    SupplierOrAgentProgramAccess() {
      if (this.user != null) {
        return this.user.need_program_access;
      }
    },
    SupplierOrAgentHasAtLeastOneProject() {
      if (this.user != null) {
        return this.user.has_at_least_one_project;
      }
    },
    SupplierVerificationRequestDate() {
      if (this.user != null) {
        return this.user.supplier_verification_request_date;
      } else {
        return null;
      }
    },    
    SupplierVerifiedDate() {
      if (this.user != null) {
        return this.user.supplier_verified_date;
      } else {
        return null;
      }
    },
    SupplierOnboardingStep(){
      if(this.user != null){
        return this.user.supplier_onboarding_step;
      }
    },
    ShowSupplierOnboarding(){
      if(this.SupplierOnboardingStep == null ){
        return true;
      } else {
        const stringStep = this.SupplierOnboardingStep[7] + this.SupplierOnboardingStep[8];
        const intStep = parseInt(stringStep);
        let lastStepInt = null;
        let lastStepString = null;
        if (this.UserPersonType == 0) {
          lastStepString = this.SupplierOnboardingLastStepPM[7] +this.SupplierOnboardingLastStepPM[8];
          lastStepInt = parseInt(lastStepString);
          return (this.SupplierOnboardingStep != this.SupplierOnboardingLastStepPM) && intStep < lastStepInt;
        } else {
          lastStepString = this.SupplierOnboardingLastStepPF[7] +this.SupplierOnboardingLastStepPF[8];
          lastStepInt = parseInt(lastStepString);
          return this.SupplierOnboardingStep != this.SupplierOnboardingLastStepPF && intStep < lastStepInt;
        }
      }
    },
    ShowSectionToCompleteSupplierOnboarding() {
      if(this.SupplierOnboardingStep == null) {
        return true;
      } else {
        const stringStep = this.SupplierOnboardingStep[7] + this.SupplierOnboardingStep[8];
        const intStep = parseInt(stringStep);
        if (this.UserPersonType == 0) {
          return (intStep >= 10 && intStep <= 20);
        } else {
          return (intStep >= 8 && intStep <= 18); 
        }
      }
    },
    supplierOnboardingCompletedAndSupplierVerified() {
      return this.ShowSectionToCompleteSupplierOnboarding && (this.SupplierStatus >= 4 || this.SupplierVerifiedDate != null);
    },
    SupplierRestrictionLevel() {
      if(this.user != null) {
        return this.user.restriction_level;
      }
    },
    AgentId(){
      if(this.user != null){
        return this.user.agent_id;
      }
    },
    ApplicantId() {
      if (this.user != null) {
        return this.user.client_id;
      }
    },
    ApplicantOnboardingStep(){
      if(this.user != null){
        return this.user.applicant_onboarding_step;
      }
    },
    ApplicantTaxProfile() {
      if (this.user != null) {
        return this.user.tax_profile;
      }
    },
    ApplicantSupplierReferred(){
      if(this.user != null){
        return this.user.supplier_referred;
      }
    },
    ShowApplicantOnboarding() {
      if (this.user != null) {
        return !(this.ApplicantOnboardingStep == this.ApplicantOnboardingLastStepPF || this.ApplicantOnboardingStep == this.ApplicantOnboardingLastStepPM);
      }
    },
    ShowApplicantMenuOptions() {
      if (this.user != null) {
        return this.user.show_menu_options;
      }
    },
    SupplierUpdateProfileInfo() {
      if (this.user != null) {
        return this.user.update_profile_info;
      }
    },
    InvestorId() {
      if (this.user != null) {
        return this.user.investor_id;
      }
    },
    InvestorStatus() {
      if (this.user != null) {
        return this.user.investor_status;
      }
    },
    InvestorOnboardingStep() {
      if (this.user != null) {
        return this.user.investor_onboarding_step;
      }
    },
    ShowInvestorOnboarding() {
      if (this.user != null) {
        return !(this.InvestorOnboardingStep == null || this.InvestorOnboardingStep == this.InvestorOnboardingLastStepPF || this.InvestorOnboardingStep == this.InvestorOnboardingLastStepPM);
      }
    },
    ShowInvestorMenuOptions() {
      if (this.user != null) {
        return this.user.show_menu_options;
      }
    },
    ResaleProjects() {
      if (this.user != null) {
        return this.user.resale_projects;
      }
    },
    AccountMetadata() {
      if (this.user != null) {
        let account_id = null;
        switch (this.user.role) {
          case "investor":
            account_id = this.user.investor_id;
            break;
          case "supplier":
            account_id = this.user.supplier_id;
            break;
          case "supplier_agent":
            account_id = this.user.agent_id;
            break;
          case "client":
            account_id = this.user.client_id;
            break;
          case "program_executive_manager":
            account_id = this.user.executive_manager_id;
            break;
          case "program_commercial_manager":
            account_id = this.user.manager_id;
            break;
          case "program_administrator":
            account_id = this.user.program_admin_id;
            break;
          default:
            account_id = null;
            break;
        }
        return {id: account_id, name: this.user.role};
      }
      return null;
    },
    HasMultipleRoles() {
      if (this.user != null) {
        return this.user.valid_roles.length > 1;
      }
      return false;
    },
    AuxGuardName() {
      if (this.user != null) {
        return this.user.auxGuardName;
      }
    },
    SupplierReferralCode() {
      if (this.user !== null) {
        return this.user.referral_code;
      }
    },
    SupplierCanUseReferalCode() {
      if (this.user !== null) {
        return this.user.can_use_code;
      }
    },
    ProgramsInfo() {
      if (this.user !== null) {
        return this.user.programInfo.programsInfo;
      } else {
        return [];
      }
    },
    currentProgramData() {
      if (this.currentProgram != null) {
        return this.currentProgram;
      } else {
        return null;
      }
    },
    CurrentGeolocationData() {
      if (this.currentGeolocation != null) {
        return this.currentGeolocation;
      } else {
        return null;
      }
    },
    SessionLocationRegistered() {
      return this.sessionLocationRegistered;
    }
  },
  methods: {
    ...mapActions('auth', ['getUserInfo', 'logoutRequest', 'apiLogoutRequest', 'getInvestorGeneralData', 'getApplicantGeneralData']),
    ...mapMutations('auth', ['setStateAccessToken']),
    ...mapMutations('extended_auth', ['cleanExtendedSession']),
    async onUserInfoUpdated() {
      await this.getUserInfo(this.UserRole);
    },
    async onInvestorGeneralDataUpdated() {
      await this.getInvestorGeneralData(this.InvestorId);
    },
    async onApplicantGeneralDataUpdated() {
      await this.getApplicantGeneralData(this.ApplicantId);
    },
    setAxiosAccessToken(token) {
      window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    },
    async executeLogout() {
      await this.logoutRequest(this.access_token);
      this.cleanExtendedSession();
      this.redirectToLogout();
    },
    async executeAppLogout() {
      await this.apiLogoutRequest(this.access_token);
      this.cleanExtendedSession();
      this.showLoading(false);
      //window.location = "/inversionistas-login-v2";
      window.location = "/login";
    },
    async executeSessionTimedLogout() {
      await this.executeSessionLogout('timeout');
    },
    async executeSessionTokenLogout() {
      await this.executeSessionLogout('lifetime');
    },
    async executeSessionLogout(reason) {
      await this.apiLogoutRequest(this.access_token);
      this.cleanExtendedSession();
      this.showLoading(false);
      window.location = `/session-logout/${reason}`;
    },
    backToLogin() {
      this.showLoading(false);
      //window.location = process.env.VUE_APP_LOGOUT_PATH;
      window.location = "/login";
    },
    redirectToLogout() {
      this.showLoading(false);
      window.location = process.env.VUE_APP_LOGOUT_PATH;
      //window.location = "/login";
    },
    redirectToLogin() {
      this.showLoading(false);
      //this.backToLogin();
      this.$router.push({name: 'login'});
    },
    showFullPageLoader() {
      const appLoading = document.getElementById('loading-bg');
      const m_app = document.getElementById('app');
      appLoading.style.display = 'block';
      m_app.style.display = 'none';
    },
    showLoading(show, text = null) {
      this.$isLoading = show;
      if (show === true) {
        if (text != null) {
          this.$vs.loading({text: text});
        } else {
          this.$vs.loading();
        }
      } else {
        this.$vs.loading.close();
      }
    },
    startLoading() {
      this.$isLoading = true;
      this.$vs.loading();
    },
    stopLoading() {
      this.$isLoading = false;
      this.$vs.loading.close();
    },
    injectCodeAccountMetadataToPayload(payload, code) {
      if (!payload.metadata) {
        payload.metadata = {};
      }
      payload.metadata.account = this.AccountMetadata;
      payload.log_code = code;
    },
    injectAccountMetadataToPayload(payload) {
      if (!payload.metadata) {
        payload.metadata = {};
      }
      payload.metadata.account = this.AccountMetadata;
    },
    injectAccountMetadataToFormData(formData) {
      if (formData) {
        formData.metadata_account_id = this.AccountMetadata.id
        formData.metadata_account_name = this.AccountMetadata.name;
      }
    },
    goToProjectsQualificationBlog() {
      window.open("https://www.redgirasol.com/modelo-de-riesgos", "_blank");
    },
    goToOdsBlog() {
      window.open("https://blog.redgirasol.com/objetivos-de-desarrollo-sostenible/", "_blank");
    },
    getRandomCrypto() {
      const crypto = window.crypto || window.msCrypto;
      let array = new Uint32Array(1);
      let random = crypto.getRandomValues(array);

      return random[0] / 10000000000;
    },
    saveApplicantLogAction(code, extraParams = {}) {
      try {
        let payload = {
          extra_params: extraParams
        };
        this.injectCodeAccountMetadataToPayload(payload, code);
        axios.post(`api/v1/applicant/${this.ApplicantId}/saveApplicantLog`, payload);
      } catch (e) {
        console.log(e);
      }
    },
    async checkIfCanUpdateSupplierOnboardingStep(step){
      try {
        const payload = {
          supplier_id: this.SupplierId
          , step_identifier: step
        };
        const res = await axios.put(`/api/supplier-onboarding/put/update-step`, payload);
        if(res.status != 200) {
          this.missingFieldsNotif(null, "Para continuar debes proporcionar toda la información solicitada.");
        }
        await this.getUserInfo("supplier");
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    async checkIfCanUpdateLoanOnboardingStep(projectId){
      try {
        const payload = {
          project_id: projectId
        };
        const res = await axios.put(`/api/loan-onboarding/put/${this.ApplicantId}/update-onboarding`, payload);
        if(res.status != 200) {
          this.missingFieldsNotif(null, "Para continuar debes proporcionar toda la información solicitada.");
        }
        return res;
      } catch (e) {
        console.log(e);
      }
    },
    saveInvestorLogAction(code, extraParams = {}){
      try {
        let payload = {
          extra_params: extraParams
        };
        this.injectCodeAccountMetadataToPayload(payload, code);
        axios.post(`api/v2/investor/${this.InvestorId}/saveInvestorLog`, payload);
      } catch (e) {
        console.log(e);
      }
    },
  }
});

import interceptors from "@/helpers/interceptors";

interceptors()

// || ---- MIX PANEL --- ||
import VueMixpanel from 'vue-mixpanel'

Vue.use(VueMixpanel, {
  token: '3193a45bd7f8cb77f0fef486ee6350ba'
})


// || ---- HOTJAR --- ||
import Hotjar from 'vue-hotjar'

Vue.use (Hotjar, {
  id: '3085022',
  isProduction: true,
  snippetVersion: 6
})

const app = new Vue({
  render: h => h(App),
  router,
  store,
  acl,
  computed: {
    ...mapState("auth", ["user", "access_token", "isLogged"]),
    ...mapState("extended_auth", ["extended_access_token", "extended_session_user"])
  },
  watch: {
    async '$store.state.isUnauthenticated'(val) {
      if (!val) {
        return;
      }

      if (this.user != null && this.UserRole === "investor") {
        await this.executeSessionTokenLogout();
      } else {
        this.cleanSession();
        this.cleanExtendedSession();
        this.$acl.change('none');
        this.redirectToLogin();
      }
    }
  },
  created: function () {
    // verificar si se tiene un token desde localStorage
    if (this.extended_access_token != null && this.extended_session_user != null) {
      // traspasar el token a la sesion normal (sessionStorage)
      this.setStateAccessToken(this.extended_access_token);
      // cargar el access token en axios desde vuex (localStorage)
      this.setAxiosAccessToken(this.extended_access_token);
      // traspasar el user al sessionStorage
      this.setUser(this.extended_session_user);
    }
  },
  mounted: async function () {
    // verificar si se tiene un token desde localStorage
    if (this.extended_access_token === null) {
      // cargar el access token en axios desde vuex (sessionStorage)
      this.setAxiosAccessToken(this.access_token);
    }
    // recargar los datos del usuario si se tiene sesion (sessionStorage)
    if (this.isLogged) {
      await this.onUserInfoUpdated();
    }
    this.$store.commit('SET_MATI_PROCESS_IS_RUNNING', false);
    this.$store.commit('SET_MATI_PROCESS_IS_FINISHED', false);
  },
  methods: {
    ...mapMutations('auth', ['cleanSession', 'setUser']),
    ...mapMutations('extended_auth', ['cleanExtendedSession']),
    approveListModal(id) {
      this.$refs["approveListModalComp"].show(id)
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    isPublic() {
      const url = window.location.pathname;
      return url.startsWith("/encuesta") || url.startsWith("/inversionistas-login-v2")
        || url.startsWith("/info-registro") || url.startsWith("/nuevo-registro/") || url.startsWith("/session-logout")
        || url.startsWith("/activar-nuevo-vendedor");
    }
  }
}).$mount('#app')
